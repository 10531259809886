import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import AboutUsBanner from '../images/Harsh.jpeg'
import AboutUsBannerCircle from '../images/about_circle.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import JayyogeshwarLogo from '../images/Jay-Yogeshwar-Logo.png'
import PeregrineLogo from '../images/Peregrine_logo.png'
import VedantaLogo from '../images/vedanta_logo.svg'
import Layout from '../components/layout'
import Seo from "../components/seo"
import TimelineSlider from "../components/Timeline-Slider"
import { Helmet } from 'react-helmet'
import 'lazysizes'
const AboutUs = () => {
    
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/about-us" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="About Us" description='eWise - About Us'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24 ">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative pb-3">Who<strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90"> we are!</strong> </h1>
                                <div className="text">
                                    <p className="mb-3">We are not just a recognized IT company but also a group of self-motivated and talented experts that help brands including enterprises, mid-size businesses and start-ups with innovative web development services.</p>
                                    <p>eWise's motive is to turn innovative ideas of people into reality! And this is exactly what we want to be known for!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4">
                        <div className="inner relative">
                            <div className="thumb w-auto relative max-w-320 sm:max-w-350 md:max-w-352 lg:max-w-384 xl:max-w-376 2xl:max-w-470 m-auto">
                                <img className='lazyload' loading="lazy" data-src={AboutUsBanner} alt="About"/>
                                <img className="absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 max-w-200 z-1 " src={AboutUsBannerCircle} alt="About Circle"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="About Shape"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="About Shape"/>
    </section>

  <section className="relative pb-10 md:pb-12 lg:pb-16 xl:pb-16">
      <div className="container pb-4 md:pb-4 lg:pb-6 ">
          <div className="-mx-4">
            <div className="text_content px-4">
                <h4 className="pb-4 md:pb-4 lg:pb-6 text-26 md:text-3xl lg:text-32 xl:text-35 2xl:text-40"><strong>Our Journey</strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p>Our journey started way back in the year 2014 has taken us to new heights as we have grown to a fully efficient business solutions’ provider; starting with only 6 team members and now with almost 12 core decision makers and team members.</p>
            </div>
          </div>
      </div>
      <TimelineSlider>

</TimelineSlider>
  </section>
  <section className="relative pb-10 md:pb-12 lg:pb-16 xl:pb-20">
    <div className="container">
        <div className="-mx-4">
          <div className="text_content px-4">
              <h4 className="pb-4 md:pb-4 lg:pb-6 text-26 md:text-3xl lg:text-32 xl:text-35 2xl:text-40"><strong>Why choose ewise</strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
              <p>Right from the inception of the company we have always believed in providing the best solutions that can elevate our clients’ business experience. Our focus has always been on open-source ecommerce, hosted ecommerce - mobile, gaming, CMS development, e-commerce development, UI/UX, Graphic design and now digital marketing. We are unstoppable when it comes to customizing solutions to suit every business need.</p>
          </div>
        </div>
    </div>
</section>

<section className="pb-16 sm:pb-20 lg:pb-28">
    <div className="container">
        <div className="-mx-4">
            <div className="our_approach_section flex items-center flex-wrap">
                <div className="left w-full lg:w-2/5 xl:w-1/2 px-4">
                    <div className="text_content">
                        <h4 className="pb-4 md:pb-4 lg:pb-6 text-26 md:text-3xl lg:text-32 xl:text-35 2xl:text-40"><strong>Our Approach</strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                        <p>Right from the inception of the company we have always believed in providing the best solutions that can elevate our clients’ business experience. Our focus has always been on open-source ecommerce, hosted ecommerce - mobile, gaming, CMS development, e-commerce development, UI/UX, Graphic design and now digital marketing. We are unstoppable when it comes to customizing solutions to suit every business need.</p>
                    </div>
                </div>
                <div className="right w-full lg:w-3/5 xl:w-1/2 px-4 mt-16 lg:mt-0 relative">
                    <div className="approach_circle flex justify-center items-center flex-wrap">

                        <div className="approach_circle_inner min-w-220 min-h-220 md:min-w-450 md:min-h-450 xl:min-w-500 xl:min-h-500 2xl:min-w-550 2xl:min-h-550  rounded-full inline-block relative">
                            <span className="arrows arrow1  absolute hidden md:inline-block right-m52 top-m29 xl:right-m62 xl:top-m34 2xl:right-m62 2xl:top-m48 border-white border-t-0 border-b-25 border-l-0 border-r-25 w-70 h-70"></span>
                            <span className="arrows arrow2  absolute hidden md:inline-block right-m21 bottom-m61 xl:right-m27 xl:bottom-m69 2xl:right-m39 2xl:bottom-m71 border-white border-t-0 border-b-25 border-l-25 border-r-0 w-70 h-70"></span>
                            <span className="arrows arrow3 absolute hidden md:inline-block left-m51 bottom-m31 xl:left-m60 xl:bottom-m39 2xl:left-m64 2xl:bottom-m46 border-white border-t-25 border-b-0 border-l-25 border-r-0 w-70 h-70"></span>
                            <span className="arrows arrow4 absolute hidden md:inline-block left-m23 top-m59 xl:left-m27 xl:top-m70 2xl:left-m36 2xl:top-m75 border-white border-t-25 border-b-0 border-l-0 border-r-25 w-70 h-70"></span>
                            <div data-text="We define brands through discovering the problem, deploying in-depth gap analysis and offering a solution at every turn that can help our clients achieve their business objectives." className="cursor-pointer text-white border-white border-5 shadow-about hover_box_process absolute min-w-80 min-h-80 md:min-w-100 md:min-h-100 rounded-full flex justify-center items-center bg-pink top-m12 left-1/2 transform -translate-x-1/2 -translate-y-1/2">Discover</div>
                            <div data-text="Our design strategy is to streamline! We always think of designing unique yet user-friendly business applications that can help your business grow." className="cursor-pointer text-white border-white border-5 shadow-about hover_box_process absolute min-w-80 min-h-80 md:min-w-100 md:min-h-100 rounded-full flex justify-center items-center bg-blue top-1/2 right-m12 transform translate-x-1/2 -translate-y-1/2">Design</div>
                            <div data-text="Leveraging latest technologies; we create with efficiency and skill, creating flexible and scalable business-driven solutions" className="cursor-pointer text-white border-white border-5 shadow-about hover_box_process absolute min-w-80 min-h-80 md:min-w-100 md:min-h-100 rounded-full flex justify-center items-center bg-pink bottom-m12 left-1/2 transform -translate-x-1/2 translate-y-1/2">Build</div>
                            <div data-text="We take an agile approach to both our work and our execution, always looking for ways to improvise what we are doing. Armed with efficient processes and one of the best teams; we strive for delivering world className technical solutions well within the stipulated timelines." className="cursor-pointer text-white border-white border-5 shadow-about hover_box_process absolute min-w-80 min-h-80 md:min-w-100 md:min-h-100 rounded-full flex justify-center items-center bg-blue top-1/2 left-m12 transform -translate-x-1/2 -translate-y-1/2">Deliver</div>

                        </div>
                        <div className="hover_content_box_text  relative md:absolute left-0 md:left-m220 lg:left-m112 2xl:left-m150 right-auto md:right-m220 lg:right-m112 2xl:right-m150 top-m-100 md:top-m65 bottom-auto md:bottom-m65  flex justify-center items-center p-0 md:p-4 text-center mt-16 md:mt-0">
                            <p id="about-services-text">We define brands through discovering the problem, deploying in-depth gap analysis and offering a solution at every turn that can help our clients achieve their business objectives.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className='relative desktop_version  pb-24  lg:pb-24  xl:pb-24  2xl:pb-24 overflow-hidden'>
      <div className='our-clients'>
        <div className='container'>
            <div className='-mx-4'>
            <div className='our-clients-wrapper flex flex-wrap'>
            <div class="left w-full px-4">
    <div class="text_content">
        <h4 class="pb-4 md:pb-4 lg:pb-6 text-26 md:text-3xl lg:text-32 xl:text-35 2xl:text-40 text-left"><strong>Take a look at our Clients</strong><span class="ml-6 hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
        <p className='text-left'>
        we did a great job with these companies, you can be next to work with.
        </p>
    </div>
</div>
<div className='right w-full mt-10 relative flex flex-wrap justify-start '>
    <div className='box w-full sm:w-1/3 lg:w-1/4 2xl:w-1/5  px-4 mb-6'>
        <div className='inner bg-white h-32 flex justify-center align-center p-4 shadow-footer hover:scale-95 transition duration-150 ease-in-out'>
            <img className='w-full h-full object-contain max-w-150 ' src={JayyogeshwarLogo} />
        </div>
    </div>
    <div className='box w-full sm:w-1/3 lg:w-1/4 2xl:w-1/5  px-4 mb-6'>
        <div className='inner bg-white h-32 flex justify-center align-center p-4 shadow-footer hover:scale-95 transition duration-150 ease-in-out'>
            <img className='w-full h-full object-contain max-w-150 ' src={VedantaLogo} />
        </div>
    </div>
    <div className='box w-full sm:w-1/3 lg:w-1/4 2xl:w-1/5  px-4 mb-6'>
        <div className='inner bg-white h-32 flex justify-center align-center p-4 shadow-footer hover:scale-95 transition duration-150 ease-in-out'>
            <img className='w-full h-full object-contain max-w-120 ' src={PeregrineLogo} />
        </div>
    </div>
    


</div>

            </div>
            </div>
        </div>
      </div>
  </section>
    </Layout>
  )
}

export default AboutUs