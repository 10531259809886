import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import eWiseTimeline from '../images/icon-256x256.png'
import ProjectIcon from '../images/project_icon.svg'
import Relocate from '../images/relocation.svg'
import CompanyLogo from '../images/company.svg'
import Location from '../images/pin.svg'

export default function TimelineSection() {
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 1,
		responsive: [
			{
			  breakpoint: 1466,
			  settings: {
				slidesToShow: 5,
				slidesToScroll: 3,
				infinite: true,
				dots: true
			  }
			}, {
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 5,
				slidesToScroll: 2
			  }
			},
			 {
			  breakpoint: 1199,
			  settings: {
				slidesToShow: 4,
				slidesToScroll: 1
			  }
			}, {
			  breakpoint: 768,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			  }
			}, {
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}]
		
	  };

return (
<div className='timeline_slider slick_slider_dots'>
  <div className='container'>
    <Slider className='timeline_slider_wrapper flex items-end' {...settings}>
      <div className='timeline_box h-300 md:h-350 lg:h-450 relative flex items-end '>
        <div className='inner timeline_box_inner1 px-4 pt-5 h-0 flex flex-wrap justify-between flex-col pr-7 border-black border-l relative pb-12 ml-m-30'>
        <span className='year_box absolute left-m-27 2xl:left-m-31 bg-pink leading-1 h-16 text-center text-white font-medium px-1 top-m23'>2014</span>
          <div className='top_content'>
            <p>The Year eWise Solutions was founded with 6 employees</p>
          </div>

        </div>
        <div className='image_box image_box1 w-16 h-16 bg-lightblue rounded-full p-4 border-pink border-4 absolute bottom-0 left-1/2 transform  translate-y-1/2  z-30'>
            <img src={CompanyLogo} />
          </div>
        <div className='border_animation1 absolute left-m-30 bottom-0 bg-black w-0 h-1 z-1'></div>
      </div>
      <div className='timeline_box h-250 md:h-300 lg:h-400 relative flex items-end '>
        <div className='inner timeline_box_inner2 px-4 pt-5 h-0 flex flex-wrap justify-between flex-col pr-7 border-black border-l relative pb-12 ml-m-30'>
        <span className='year_box absolute left-m-27 2xl:left-m-31 bg-pink leading-1 h-16 text-center text-white font-medium px-1 top-m23'>2016</span>
          <div className='top_content'>
            <p>We have completed more then 10 project and 5+ ecommerce stores with excellent reviews from clients</p>
          </div>

        </div>
        <div className='image_box image_box2  w-16 h-16 bg-lightblue rounded-full p-4 border-pink border-4 absolute bottom-0 left-1/2 transform translate-y-1/2 z-30'>
            <img src={ProjectIcon} />
          </div>
        <div className='border_animation2 absolute left-m-30 bottom-0 bg-black w-full h-1 z-1'></div>
      </div>
      <div className='timeline_box h-300 md:h-350 lg:h-450 relative flex items-end '>
        <div className='inner timeline_box_inner3 px-4 pt-5 h-0 flex flex-wrap justify-between flex-col pr-7 border-black border-l relative pb-12 ml-m-30'>
        <span className='year_box absolute left-m-27 2xl:left-m-31 bg-pink leading-1 h-16 text-center text-white font-medium px-1 top-m23'>2018</span>
          <div className='top_content'>
            <p>We have relocated our office with new strength of team members and projects.</p>
          </div>

        </div>
        <div className='image_box image_box3 w-16 h-16 bg-lightblue rounded-full p-4 border-pink border-4 absolute bottom-0 left-1/2 transform  translate-y-1/2  z-30'>
            <img src={Relocate} />
          </div>
        <div className='border_animation3 absolute left-m-30 bottom-0 bg-black w-full h-1 z-1'></div>
      </div>
      <div className='timeline_box h-250 md:h-300 lg:h-400 relative flex items-end '>
        <div className='inner timeline_box_inner4 px-4 pt-5 h-0 flex flex-wrap justify-between flex-col pr-7 border-black border-l relative pb-12 ml-m-30'>
        <span className='year_box absolute left-m-27 2xl:left-m-31 bg-pink leading-1 h-16 text-center text-white font-medium px-1 top-m23'>2019</span>
          <div className='top_content'>
            <p>We have opened up separate division for ecommerce projects.</p>
          </div>

        </div>
        <div className='image_box image_box4 w-16 h-16 bg-lightblue rounded-full p-4 border-pink border-4 absolute bottom-0 left-1/2 transform translate-y-1/2 z-30'>
            <img src={CompanyLogo} />
          </div>
        <div className='border_animation4 absolute left-m-30 bottom-0 bg-black w-full h-1 z-1'></div>
      </div>
      <div className='timeline_box h-300 md:h-350 lg:h-450 relative flex items-end '>
        <div className='inner timeline_box_inner5 px-4 pt-5 h-0 flex flex-wrap justify-between flex-col pr-7 border-black border-l relative pb-12 ml-m-30'>
        <span className='year_box absolute left-m-27 2xl:left-m-31 bg-pink leading-1 h-16 text-center text-white font-medium px-1 top-m23'>2021</span>
          <div className='top_content'>
            <p>We have opened new office in Kenya</p>
          </div>

        </div>
        <div className='image_box image_box5 w-16 h-16 bg-lightblue rounded-full p-4 border-pink border-4 absolute bottom-0 left-1/2 transform translate-y-1/2 z-30'>
            <img src={Location} />
          </div>
        <div className='border_animation5 absolute left-m-30 bottom-0 bg-black w-full h-1 z-1'></div>
      </div>
      <div className='timeline_box h-250 md:h-300 lg:h-400 relative flex items-end '>
        <div className='inner timeline_box_inner6 px-4 pt-5 h-0 flex flex-wrap justify-between flex-col pr-7 border-black border-l relative pb-12 ml-m-30'>
        <span className='year_box absolute left-m-27 2xl:left-m-31 bg-pink leading-1 h-16 text-center text-white font-medium px-1 top-m23'>2022</span>
          <div className='top_content'>
            <p>We have opened new office in Dubai</p>
          </div>

        </div>
        <div className='image_box image_box6 w-16 h-16 bg-lightblue rounded-full p-4 border-pink border-4 absolute bottom-0 left-1/2 transform  translate-y-1/2 z-30'>
            <img src={Location} />
          </div>
        <div className='border_animation6 absolute left-m-30 bottom-0 bg-black w-full h-1 z-1'></div>
      </div>
    </Slider>
  </div>
</div>
);
}